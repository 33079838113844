<template>
  <div>
    <main class="page-content">
      <div class="account-page-area">
      <div class="">
          <div class="row">
            <div class="col-lg-3 account_border-right">
              <sideMenuVendor></sideMenuVendor>
            </div>

            <div class="col-lg-9">
              <div>
                <div
                  class="card card-container registerFormwrap"
                  style="margin: 0px"
                >
                  <Form
                    @submit="onSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                  >
                    <div class="row">
                      <div class="form-group col-md-3 col-6">
                        <label>Brand </label>

                        <Field
                          name="vehBrand"
                          as="select"
                          class="form-control"
                          :class="{ 'is-invalid': errors.vehBrand }"
                          v-model="vehiclenfovendor.brandName"
                          @change="getvehicleModels(vehiclenfovendor)"
                        >
                          <option
                            v-for="brand in VechBrand"
                            :key="brand"
                            :value="brand.make"
                          >
                            {{ brand.make }}
                          </option>
                        </Field>
                        <div class="invalid-feedback">
                          {{ errors.vehBrand }}
                        </div>
                      </div>
                      <div class="form-group col-md-3 col-6">
                        <label>Model Name</label>
                        <Field
                          name="vehModel"
                          as="select"
                          class="form-control"
                          :class="{ 'is-invalid': errors.vehModel }"
                          v-model="vehiclenfovendor.model"
                        >
                          <option
                            v-for="(vehModel, index) in VechModels"
                            :key="index"
                            :value="vehModel.model"
                          >
                            {{ vehModel.model }}
                          </option>
                        </Field>
                        <div class="invalid-feedback">
                          {{ errors.vehModel }}
                        </div>
                      </div>

                      <div class="form-group col-md-3 col-6">
                        <label>Vehicle Type</label>
                        <Field
                          name="vehTypeName"
                          as="select"
                          class="form-control"
                          :class="{ 'is-invalid': errors.vehTypeName }"
                          v-model="vehiclenfovendor.vehTypeName"
                        >
                          <!-- <option >{{vehiclenfovendor.vehTypeName}}</option> -->
                          <option
                            v-for="Type in VechType"
                            :key="Type"
                            :value="Type.vehTypeName"
                          >
                            {{ Type.vehTypeName }}
                          </option>
                        </Field>
                        <div class="invalid-feedback">
                          {{ errors.vehModel }}
                        </div>
                      </div>
                      <div class="form-group col-md-3 col-6">
                        <label>Vin Number</label>
                        <Field
                          name="vinNumber"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.vinNumber }"
                          v-model="vehiclenfovendor.vehRegNo"
                        />
                        <div class="invalid-feedback">
                          {{ errors.vinNumber }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-3 col-6">
                        <label>Km Clocked</label>
                        <Field
                          name="kmClocked"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.kmClocked }"
                          v-model="vehiclenfovendor.kmClocked"
                        />
                        <div class="invalid-feedback">
                          {{ errors.kmClocked }}
                        </div>
                      </div>
                      <div class="form-group col-md-3 col-6">
                        <label>Year of Make</label>
                        <Field
                          name="yearofmake"
                          type="number"
                          class="form-control"
                          :class="{ 'is-invalid': errors.yearofmake }"
                          v-model="vehiclenfovendor.manYear"
                        />
                        <div class="invalid-feedback">
                          {{ errors.yearofmake }}
                        </div>
                      </div>
                      <div class="form-group col-md-3 col-6">
                        <label>Vehicle Price</label>
                        <Field
                          name="vehPrice"
                          type="number"
                          class="form-control"
                          :class="{ 'is-invalid': errors.vehPrice }"
                          v-model="vehiclenfovendor.vehPrice"
                        />
                        <div class="invalid-feedback">
                          {{ errors.vehPrice }}
                        </div>
                      </div>
                      <div class="form-group col-md-3 col-6">
                        <label>Warranty</label>
                        <Field
                          name="warrenty"
                          as="select"
                          class="form-control"
                          :class="{ 'is-invalid': errors.warrenty }"
                          v-model="activite"
                        >
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Field>
                        <div class="invalid-feedback">
                          {{ errors.warrenty }}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-md-3 col-6">
                        <label>State</label>
                        <Field
                          name="state"
                          as="select"
                          class="form-control"
                          :class="{ 'is-invalid': errors.state }"
                          @change="getDistrictbyLocId($event)"
                          v-model="vehiclenfovendor.state"
                        >
                          <option
                            v-for="Statein in States"
                            :key="Statein"
                            :value="Statein.state"
                          >
                            {{ Statein.state }}
                          </option>
                        </Field>
                        <div class="invalid-feedback">{{ errors.state }}</div>
                      </div>
                      <div class="form-group col-md-3 col-6">
                        <label>District</label>
                        <Field
                          name="district"
                          as="select"
                          class="form-control"
                          :class="{ 'is-invalid': errors.district }"
                          @click="getLocationByStateId($event)"
                          v-model="vehiclenfovendor.district"
                        >
                          <option selected>
                            {{ vehiclenfovendor.district }}
                          </option>
                          <option
                            v-for="districtin in Districts"
                            :key="districtin"
                            :value="districtin.district"
                          >
                            {{ districtin.district }}
                          </option>
                        </Field>
                        <div class="invalid-feedback">
                          {{ errors.district }}
                        </div>
                      </div>
                      <div class="form-group col-md-3 col-6">
                        <label>City</label>
                        <Field
                          name="city"
                          as="select"
                          class="form-control"
                          :class="{ 'is-invalid': errors.city }"
                          v-model="vehiclenfovendor.locName"
                        >
                          <option selected>
                            {{ vehiclenfovendor.locName }}
                          </option>
                          <option
                            v-for="cityin in City"
                            :key="cityin.locId"
                            :value="cityin.locName"
                          >
                            {{ cityin.locName }}
                          </option>
                        </Field>
                        <div class="invalid-feedback">{{ errors.city }}</div>
                      </div>
                      <div class="form-group col-md-3 col-6">
                        <label>Description</label>
                        <Field
                          name="description"
                          type="textarea"
                          class="form-control"
                          :class="{ 'is-invalid': errors.description }"
                          v-model="vehiclenfovendor.vehdescription"
                        />
                        <div class="invalid-feedback">
                          {{ errors.description }}
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <!-- image upload -->
                        <!-- <div v-if="imagesArry"
                       style="display: flex; flex-wrap: wrap"> 
                        
                          <div v-for="image in imagesArry" :key="image">
                               <img :src="path + image" style="width: 170px" />     
                          </div>
                      </div> -->
                        <div>
                          <div class="imageupload">
                            <h2 style="font-size: 15px">Select an image</h2>
                            <input
                              multiple
                              type="file"
                              @change="onFileChange"
                            />
                          </div>
                          <div>
                            <a
                              class="update-clear"
                              style="cursor: pointer"
                              @click="removeAllImage"
                              >clear all</a
                            >
                          </div>
                          <div
                            v-if="imagesArry"
                            style="display: flex; flex-wrap: wrap"
                          >
                            <div
                              v-for="(image, index) in imagesArry"
                              :key="index"
                              style="display: grid"
                            >
                              <img
                                v-if="image"
                                :src="image"
                                style="
                                  width: 170px;
                                  height: 120px;
                                  object-fit: cover;
                                "
                              />
                              <a
                                @click="removeImage(index)"
                                style="
                                  background-color: #ffffff;
                                  color: black;
                                  padding: 5px;
                                  border-radius: 5px;
                                  border: 1px solid #c7c7c7;
                                  box-shadow: 1px 1px #0000004d;
                                  text-align: center;
                                "
                              >
                                <i class="fad fa-trash-alt"></i>
                              </a>
                            </div>
                          </div>
                          <div
                            v-if="images"
                            style="display: flex; flex-wrap: wrap"
                          >
                            <div
                              v-for="(image, index) in images"
                              :key="index"
                              style="
                                display: grid;
                                position: relative;
                                margin: 5px;
                              "
                            >
                              <img
                                :src="path + image"
                                style="
                                  width: 170px;
                                  height: 120px;
                                  object-fit: cover;
                                "
                              />
                              <a
                                @click="removeImage(index)"
                                class="update-v-dlt"
                              >
                                <i class="fad fa-trash-alt"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <button
                        style="background-color: #002868; color: white"
                        class="btn btn-primary btn-block"
                        :disabled="loading"
                      >
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        Save Changes
                      </button>
                    </div>
                  </Form>
                  <!-- <div
                    v-if="message"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ message }}
                  </div> -->
                  <div
                    v-if="imagesmessage"
                    class="alert"
                    :class="'alert-danger'"
                  >
                    {{ imagesmessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import sideMenuVendor from "@/components/sideMenuVendor.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import Config from "@/config.js";

export default {
  name: "Add Vehicles",
  components: {
    Form,
    Field,
    // FieldArray,
    sideMenuVendor,
  },
  data() {
    const schema = Yup.object().shape({
      vehBrand: Yup.string().required("Select a Brand "),
      vehModel: Yup.string().required("Select a Model "),
      vehTypeName: Yup.string().required("Select a Type "),
      vinNumber: Yup.string().required("Vin numer is required"),
      kmClocked: Yup.string().required("Kilometer is required"),
      vehPrice: Yup.string().required("Vehicle price is required"),
      yearofmake: Yup.string().required("Year of make is required"),
      warrenty: Yup.string().required("Warrenty is required"),
      state: Yup.string().required("State is required"),
      district: Yup.string().required("District is required"),
      city: Yup.string().required("City is required"),
      description: Yup.string().required("Description is required"),
      vehImage: "",
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
      userinfo: [],
      VechBrand: [],
      VechModels: [],
      VechType: [],
      States: [],
      Districts: [],
      City: [],
      imagesArry: [],
      vehiclenfovendor: [],
      activite: "",
      locationId: "",
      images: [],
      images2: [],
      images3: [],
      images4: [],
      images5: [],
      images6: [],
      images7: [],
      images8: [],
      images9: [],
      images10: [],
      images11: [],
      images12: [],
      images13: [],
      images14: [],
      images15: [],
      path: Config.BASE_URL_IMG,
      imagesmessage: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    // if (this.loggedIn) {
    //   this.$router.push("/vendorprofile");
    // }

    //this.getVehicleBrand();
    //this.getVehicleModel();
    //this.getVehicleTypes();
   // this.getAllStates();
    window.scrollTo(0, 0);
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));

      if (typeof this.userinfo.sellerId != "undefined") {
        this.getVehiclesDetailsvehId();
        this.getVehicleBrand();
        // this.getVehicleModel();
        this.getVehicleTypes();
        this.getAllStates();
      }
    }
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files);
    },
    createImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.imagesArry.push(imageUrl);
        };
        reader.readAsDataURL(files[index]);
      }
    },
    removeImage(index) {
      this.imagesArry.splice(index, 1);
      this.images.splice(index, 1);
    },
    removeAllImage() {
      this.imagesArry = [];
      this.images = [];
    },
    getVehiclesDetailsvehId() {
      let sellerId = this.userinfo.sellerId;
      let vehId = this.$route.params.url_key;

      if (sellerId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getVehiclesDetailsvehId(sellerId, vehId)
          .then((response) => {
           // console.log(this.vehiclenfovendor);
            this.vehiclenfovendor = response.data.Data[0];

            let carsimages = [];

            if (
              this.vehiclenfovendor.vehImage1 != null &&
              this.vehiclenfovendor.vehImage1 != ""
            ) {
              carsimages[0] = this.vehiclenfovendor.vehImage1;
            }
            // else {
            //   carsimages[0] =  "";
            // }
            if (
              this.vehiclenfovendor.vehImage2 != null &&
              this.vehiclenfovendor.vehImage2 != ""
            ) {
              // console.log(this.vehiclenfovendor.vehImage2);
              carsimages[1] = this.vehiclenfovendor.vehImage2;
            }
            //  else {
            //   carsimages[1] =  "";
            // }
            if (
              this.vehiclenfovendor.vehImage3 != null &&
              this.vehiclenfovendor.vehImage3 != ""
            ) {
              carsimages[2] = this.vehiclenfovendor.vehImage3;
            }
            // else {
            //   carsimages[2] =  "";
            // }
            if (
              this.vehiclenfovendor.vehImage4 != null &&
              this.vehiclenfovendor.vehImage4 != ""
            ) {
              carsimages[3] = this.vehiclenfovendor.vehImage4;
            }
            // else {
            //   carsimages[3] =  "";
            // }
            if (
              this.vehiclenfovendor.vehImage5 != null &&
              this.vehiclenfovendor.vehImage5 != ""
            ) {
              carsimages[4] = this.vehiclenfovendor.vehImage5;
            }

            // else {
            //   carsimages[4] =  "";
            // }

            if (
              this.vehiclenfovendor.vehImage6 != null &&
              this.vehiclenfovendor.vehImage6 != ""
            ) {
              carsimages[5] = this.vehiclenfovendor.vehImage6;
            }
            // else {
            //   carsimages[5] =  "";
            // }
            if (
              this.vehiclenfovendor.vehImage7 != null &&
              this.vehiclenfovendor.vehImage7 != ""
            ) {
              carsimages[6] = this.vehiclenfovendor.vehImage7;
            }
            // else {
            //   carsimages[6] = "";
            // }
            if (
              this.vehiclenfovendor.vehImage8 != null &&
              this.vehiclenfovendor.vehImage8 != ""
            ) {
              carsimages[7] = this.vehiclenfovendor.vehImage8;
            }
            //  else {
            //   carsimages[7] = "";
            // }
            if (
              this.vehiclenfovendor.vehImage9 != null &&
              this.vehiclenfovendor.vehImage9 != ""
            ) {
              carsimages[8] = this.vehiclenfovendor.vehImage9;
            }
            // else {
            //   carsimages[8] = "";
            // }
            if (
              this.vehiclenfovendor.vehImage10 != null &&
              this.vehiclenfovendor.vehImage10 != ""
            ) {
              carsimages[9] = this.vehiclenfovendor.vehImage10;
            }
            // else {
            //   carsimages[9] = "";
            // }
            if (
              this.vehiclenfovendor.vehImage11 != null &&
              this.vehiclenfovendor.vehImage11 != ""
            ) {
              carsimages[10] = this.vehiclenfovendor.vehImage11;
            }
            // else {
            //   carsimages[10] = "";
            // }
            if (
              this.vehiclenfovendor.vehImage12 != null &&
              this.vehiclenfovendor.vehImage12 != ""
            ) {
              carsimages[11] = this.vehiclenfovendor.vehImage12;
            }
            // else {
            //   carsimages[11] = "";
            // }
            if (
              this.vehiclenfovendor.vehImage13 != null &&
              this.vehiclenfovendor.vehImage13 != ""
            ) {
              carsimages[12] = this.vehiclenfovendor.vehImage13;
            }
            //  else {
            //   carsimages[12] = "";
            // }
            if (
              this.vehiclenfovendor.vehImage14 != null &&
              this.vehiclenfovendor.vehImage14 != ""
            ) {
              carsimages[13] = this.vehiclenfovendor.vehImage14;
            }
            // else {
            //   carsimages[13] = "";
            // }
            if (
              this.vehiclenfovendor.vehImage15 != null &&
              this.vehiclenfovendor.vehImage15 != ""
            ) {
              carsimages[14] = this.vehiclenfovendor.vehImage15;
            }
            //  else {
            //   carsimages[14] = "";
            // }

            this.images = carsimages;

            let warrnty = JSON.stringify(response.data.Data[0].underWarantee);
            if (warrnty == "true") {
              this.activite = "Yes";
            } else if (warrnty == "false") {
              this.activite = "No";
            }
            // this.imageArryConvertion();
          })
          .catch((e) => {
            console.log(e);
            this.$router.push("/VehicleList").catch(() => {
              "MyVehicleListings";
            });
          });
      }
    },

    onSubmit(user) {
      this.message = "";
      this.imagesmessage = "";
      this.loading = true;

      userService
        .getLocationIdbyLocName(user.city)
        .then((response) => {
         // console.log(response);
          this.locationId = response.data.Data[0];
          user.city = this.locationId.locId;
          user.vehType = this.vehiclenfovendor.vehTypeId;
          user.vehId = this.vehiclenfovendor.vehId;
          user.sellerId = this.userinfo.sellerId;
          this.updateVechDetailsbyloc(user);
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.$toast.error("!Oops sothing went wrong. try again", {
              position: "top",
              duration: 3000,
            });
        });
    },
    updateVechDetailsbyloc(user) {
      let imageLenght = this.imagesArry.length + this.images.length;
      if (imageLenght <= 0) {
        this.imagesmessage = "Add atleast one image";
        this.loading = false;
      } else if (imageLenght > 15) {
        this.imagesmessage = "Can't add more than 15 images";
        this.loading = false;
      } else {
        this.imagesmessage = "";
        let combinedImageArry = this.imagesArry.concat(this.images);
        userService
          .updateVechDetails(user, combinedImageArry)
          .then((response) => {
            this.message = response.data.Message;
            this.$toast.success("Updated suceesfully", {
              position: "top",
              duration: 3000,
            });
            this.successful = true;
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.$toast.error("!Oops sothing went wrong. try again", {
              position: "top",
              duration: 3000,
            });
            this.loading = false;
            this.$router.go(-1);
          });
      }
    },

    getVehicleBrand() {
      
      userService
        .getVehicleBrand()
        .then((response) => {
          this.VechBrand = response.data.Data;
          //console.log(this.VechBrand);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getvehicleModels(make) {
     // alert(make.brandName);
      userService
        .getVehicleModel(make.brandName)
        .then((response) => {
          this.VechModels = response.data.Data;
          // console.log(this.VechModels);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    // getVehicleModel() {
    //   userService
    //     .getVehicleModel()
    //     .then((response) => {
    //       this.VechModels = response.data.Data;
    //       console.log(this.VechModels);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       // this.$router.push("/Auction").catch(() => {
    //       //   "details/auctionbyname";
    //       // });
    //     });
    // },
    getVehicleTypes() {
      userService
        .getVehicleTypes()
        .then((response) => {
          this.VechType = response.data.Data;
          // console.log(this.VechType);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getAllStates() {
      let id = this.userinfo.sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getStatebyLocId()
          .then((response) => {
            this.States = response.data.Data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getDistrictbyLocId(event) {
      let id = this.userinfo.sellerId;
      let statename = event.target.value;
      this.vehiclenfovendor.district = "";
      this.vehiclenfovendor.locName = "";
      // console.log(event);
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getDistrictbyLocId(statename)
          .then((response) => {
            this.Districts = response.data.Data;
            // console.log(JSON.stringify(response.data.Data));
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getLocationByStateId(event) {
      let id = this.userinfo.sellerId;
      let district = event.target.value;
      // console.log(event);
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getLocationByStateId(district)
          .then((response) => {
            this.City = response.data.Data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    // goToLogin() {
    //   setTimeout(() => this.$router.push("/vendorLogin"), 5000);
    // },
  },
};
</script>
